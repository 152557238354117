import React from 'react';
import classNames from 'classnames';
import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import { UUID } from 'angular2-uuid';
import { cancelRequest } from 'app/util/util';
import './autocomplete.component.scss';
import { Translation } from '../translation/translation.component';

const ENTER_KEY = 13;
const UP_ARROW = 38;
const DOWN_ARROW = 40;
const TAB_KEY = 9;

const VALID_KEYS = [UP_ARROW, DOWN_ARROW, ENTER_KEY, TAB_KEY];
const recentSearchesMax = 5;

export interface Props {
  value: AnyAutocompleteResult;
  mapper: (query: string) => Observable<AnyAutocompleteResult[]>;
  onChange: (value: AnyAutocompleteResult) => any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => any;
  disabled?: boolean;
  suppressSingleResults?: boolean;
  name?: string;
  maxResults?: number;
  placeholder?: string;
  required?: boolean;
  selectOnClickOut?: boolean;
  labelHeader?: string;
  recentSearches?: SearchHistoryLocationJSON[];
}

export interface State {
  selectedIndex: number;
  isFocused: boolean;
  loading: boolean;
  results: AnyAutocompleteResult[];
  recentMatches?: SearchHistoryLocationJSON[];
}

export type AnyAutocompleteResult = AutocompleteResult<any>;
export type RawAutocompleteResult = AutocompleteResult<string>;

export interface AutocompleteResult<T> {
  id: string | number;
  title: string;
  value: T;
  isRecentSearchSelection?: boolean;
}

export class Autocomplete extends React.Component<Props, State> {
  static defaultProps: {
    suppressSingleResults: true,
  };

  query$: Subject<string>;
  subscription: Subscription;
  optionRefs: HTMLLIElement[] = [];
  wrapper: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      isFocused: false,
      loading: false,
      results: [],
      recentMatches: null
    };

    this.query$ = new Subject();
    this.subscribeMapper();
  }

  outsideClickHandler = (e: Event) => {
    if (this.wrapper && !this.wrapper.contains(e.target as any) && this.state.isFocused) {
      this.setState({isFocused: false});
    }
  };

  componentDidMount() {
    if (this.props.recentSearches?.length > 0) {
      this.setState({recentMatches: this.props.recentSearches.slice(0, recentSearchesMax)});
    }
    this.query$.next(this.props.value ? this.props.value.title : '');
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
    this.query$.next(nextProps.value ? nextProps.value.title : '');
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.mapper !== this.props.mapper) {
      this.subscribeMapper();
    }
    if (prevState.isFocused !== this.state.isFocused) {
      if (prevState.isFocused) {
        document.removeEventListener('mousedown', this.onDocumentClick);
        document.removeEventListener('mousedown', this.outsideClickHandler);
      } else {
        document.addEventListener('mousedown', this.onDocumentClick);
        document.addEventListener('mousedown', this.outsideClickHandler);
      }
    }
    if (prevProps.recentSearches !== this.props.recentSearches) {
      this.setState({recentMatches: this.props.recentSearches.slice(0, recentSearchesMax)});
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.outsideClickHandler);
    document.removeEventListener('mousedown', this.onDocumentClick);
    cancelRequest(this.subscription);
  }

  private subscribeMapper() {
    cancelRequest(this.subscription);

    this.subscription = this.query$
      .debounceTime(500)
      .map(query => {
        this.setState({loading: true});
        return query;
      })
      .switchMap(this.props.mapper)
      .subscribe(results => this.setState({
        results,
        loading: false
      }));
  }

  onBlur = (e?: React.FocusEvent<HTMLInputElement>) => {
    if (this.wrapper && this.wrapper.contains(document.activeElement)) {
      return;
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    this.setState({isFocused: false});
  };

  onDocumentClick = (e: Event) => {
    const {results} = this.state;
    const {selectedIndex} = this.state;

    if ((this.wrapper && (this.wrapper.contains(e.target as Node)))) {
      return;
    }

    if (!results || !results.length) {
      return;
    }

    // When user clicks out of input, run onChange like we would if they tabbed out
    if (this.props.selectOnClickOut) {
      this.props.onChange(results[selectedIndex]);
      this.setState({selectedIndex: 0});
    }
  };

  onFocus = () => {
    this.setState({isFocused: true});
  };

  onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.query$.next(value);
    this.props.onChange({id: null, title: value, value});

    if (this.props?.recentSearches?.length > 0) {
      this.filterRecentSearches(value);
    }

    this.onFocus();
  };

  onSelect = (result: AnyAutocompleteResult) => () => {
    result.isRecentSearchSelection = false;
    this.props.onChange(result);
    this.setState({isFocused: false});
    this.onBlur();
  };

  onRecentSearchSelect = ({city, stateProvinceCode, countryName, countryCode, latitude, longitude}: SearchHistoryLocationJSON) => () => {
    const locationDisplayText = this.getLocationDisplayText(stateProvinceCode, city, countryName);
    // if coords are missing, interimAPI is used
    const selectedResult = {
      isRecentSearchSelection: true,
      id: UUID.UUID(),
      title: locationDisplayText,
      value: {
        cityName: city,
        stateProvinceCode,
        countryCode,
        countryName,
        displayName: locationDisplayText,
        coordinate: {
          latitude,
          longitude,
        }
      }
    };

    this.props.onChange(selectedResult);
    this.setState({isFocused: false});
    this.onBlur();
  };

  onKeyDown = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    if (VALID_KEYS.indexOf(e.keyCode) === -1 || !this.showDropdown()) {
      return;
    }

    if (e.keyCode !== TAB_KEY) {
      e.preventDefault();
    }

    this.navigateDropdown(e.keyCode);
  };

  getLocationDisplayText(stateProvinceCode: string, city: string, countryName: string) {
    return stateProvinceCode ? `${city}, ${stateProvinceCode}, ${countryName}` : `${city}, ${countryName}`;
  }

  showDropdown() {
    const {isFocused, results, recentMatches} = this.state;
    const {value, suppressSingleResults} = this.props;

    const hasResults = results && results.length && // has search results
      !(suppressSingleResults && (results.length === 1 && value === results[0])) && // unless there is only 1 item, and it is already selected
      isFocused;

    const hasRecentMatches = recentMatches && recentMatches.length > 0 && isFocused;

    return Boolean(
      hasResults || hasRecentMatches
    );
  }

  navigateDropdown(keyCode: number) {
    const {results} = this.state;
    let newSelectedIndex = this.state.selectedIndex;
    switch (keyCode) {
      case UP_ARROW:
        newSelectedIndex--;
        if (newSelectedIndex < 0) {
          newSelectedIndex = 0;
        }
        break;
      case DOWN_ARROW:
        newSelectedIndex++;
        if (newSelectedIndex >= results?.length) {
          newSelectedIndex = results?.length - 1;
        }
        break;
      case ENTER_KEY:
      case TAB_KEY:
        if (!results?.length) {
          return;
        }
        this.onSelect(results[newSelectedIndex])();
        newSelectedIndex = 0;
    }
    // this allows using the arrow keys to scroll the selected item into view if the autocomplete has a scrollbar.
    if (this.optionRefs[newSelectedIndex]) {
      this.optionRefs[newSelectedIndex].scrollIntoView(false);
    }
    this.setState({selectedIndex: newSelectedIndex});
  }

  getActiveClasses(result: AutocompleteResult<any>, index: number) {
    const {selectedIndex} = this.state;
    const {value} = this.props;
    return classNames({'selected': index === selectedIndex, 'current': value && value.id === result.id});
  }

  filterRecentSearches(value: string) {
    const parts = value.split(',').map(v => v.toLowerCase().trim());
    const recentMatches = this.props.recentSearches.filter(record => {
      // city requires a comma after it
      const city = `${record.city.toLocaleLowerCase()},`;
      if (parts[0]?.length > 0 && !city.startsWith(parts[0])) {
        return false;
      }

      if (parts[1]?.length > 0 && parts[1].length <= 2) {
        const startsWithStateCode = record.stateProvinceCode != null && record.stateProvinceCode.toLocaleLowerCase().startsWith(parts[1]);
        const startsWithCountryCode = record.countryCode != null && record.countryCode.toLocaleLowerCase().startsWith(parts[1]);
        const startsWithCountryName = record.countryName != null && record.countryName.toLocaleLowerCase().startsWith(parts[1]);

        if (!startsWithStateCode && !startsWithCountryCode && !startsWithCountryName) {
          return false;
        }
      }

      if (parts[1]?.length > 2) {
        const includesStateCode = record.stateProvinceCode != null && parts[1].includes(record.stateProvinceCode.toLocaleLowerCase());
        const includesCountryCode = record.countryCode != null && parts[1].includes(record.countryCode.toLocaleLowerCase());
        const includesCountryName = record.countryName != null && parts[1].includes(record.countryName.toLocaleLowerCase());
        const startsWithCountryName = record.countryName != null && record.countryName.toLocaleLowerCase().startsWith(parts[1]);

        if (!startsWithCountryName && !includesStateCode && !includesCountryCode && !includesCountryName) {
          return false;
        }
      }

      return true;
    });

    const maxDisplay = recentMatches.slice(0, recentSearchesMax);

    this.setState({recentMatches: maxDisplay});
  }

  render() {
    const {results, loading, recentMatches} = this.state;
    const {value, disabled, name, placeholder, maxResults, labelHeader} = this.props;
    const maxResultsMinusRecents = 10 - recentMatches?.length;
    const resultsToDisplay = recentMatches?.length > 0 ? maxResultsMinusRecents : maxResults;
    this.optionRefs.length = 0;

    return (
      <div className="autocomplete" ref={ref => this.wrapper = ref}>
        <span className={loading ? ' ns-icon ns-loading' : ''}/>
        <input
          type="text"
          id={name}
          name={name}
          className="input form-control"
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onInput}
          onKeyDown={this.onKeyDown}
          value={value.title || ''}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
        />
        {this.showDropdown() &&
        <div className="suggestions">
          {recentMatches?.length > 0 &&
            <>
            <span className="small text-muted space-outer-left-sm">
              <Translation resource="RECENT"/>
            </span>
            <ul className="recent-searches">
              {recentMatches.map((result: any, index) =>
                <li key={index} className="recent-search-item">
                  <a
                    href="#"
                    className="recent-search-content"
                    onMouseDown={this.onRecentSearchSelect(result)}
                    onTouchStart={this.onRecentSearchSelect(result)}
                  >
                    <span className="history-icon" />
                    <span>{this.getLocationDisplayText(result.stateProvinceCode, result.city, result.countryName)}</span>
                  </a>
                </li>
              )}
            </ul>
            </>
          }
          {results?.length > 0 &&
            <ul className="autocomplete-results">
              <label>{labelHeader}</label>
              {results?.slice(0, resultsToDisplay).map((result: AnyAutocompleteResult, index) =>
                <li ref={ref => this.optionRefs[index] = ref} key={result.id}>
                  <a className={this.getActiveClasses(result, index)}
                    onMouseDown={this.onSelect(result)}
                    onTouchStart={this.onSelect(result)}
                  >{result.title}</a>
                </li>
              )}
            </ul>
          }
        </div>
        }
      </div>
    );
  }
}
