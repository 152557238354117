import React from 'react';
import { ChangeEvent, FocusEvent } from 'react';
import { Observable } from 'rxjs/Observable';

export interface LocationSelectContextType {
  states?: StateResponse[];
  countries?: CountryResponse[];
  id?: string;
  label?: string;
  disabled?: boolean;
  location?: LocationSelectGrouping;
  onCountryChange?: (country: CountryResponse) => any;
  onStateProvChange?: (country: StateResponse) => any;
  onCityChange?: (city: CityResponse) => any;
  onPlaceChange?: (place: any) => any;
  onLocationChange?: (local: any) => any;
  onPostalCodeChange?: (e: ChangeEvent<HTMLInputElement>) => any;
  onRadiusChange?: (e: ChangeEvent<HTMLInputElement>) => any;
  onBlur?: (e: FocusEvent<HTMLSelectElement|HTMLInputElement>) => any;
  fetchCities?:  (query) => Observable<CityResponse[]>;
  fetchLocations?: (query) => Observable<any[]>;
}

export const LocationSelectContext = React.createContext<LocationSelectContextType>({
  states: [],
  countries: [],
  id: null,
  label: '',
  disabled: false,
  location: null,
  onCountryChange: null,
  onStateProvChange: null,
  onCityChange: null,
  onPlaceChange: null,
  onLocationChange: null,
  onPostalCodeChange: null,
  onRadiusChange: null,
  onBlur: null,
  fetchCities: null,
  fetchLocations: null,
});
